define('ember-changeset/utils/computed/object-to-array', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = objectToArray;
  var computed = Ember.computed,
      get = Ember.get,
      typeOf = Ember.typeOf;
  var keys = Object.keys;

  var assign = Ember.assign || Ember.merge;

  function objectToArray(objKey, flattenObjects) {
    return computed(objKey, function () {
      var obj = get(this, objKey);

      return keys(obj).map(function (key) {
        var value = obj[key];

        if (flattenObjects && typeOf(value) === 'object') {
          return assign({ key: key }, value);
        }

        return { key: key, value: value };
      });
    }).readOnly();
  }
});