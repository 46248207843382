define("ember-svg-jar/inlined/close-light", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M1.575.868a1.5 1.5 0 012.122 0l5.302 5.303L14.303.868a1.5 1.5 0 012.122 0l.707.707a1.5 1.5 0 010 2.122l-5.304 5.302 5.304 5.304a1.5 1.5 0 010 2.122l-.707.707a1.5 1.5 0 01-2.122 0L9 11.828l-5.302 5.304a1.5 1.5 0 01-2.122 0l-.707-.707a1.5 1.5 0 010-2.122L6.171 9 .868 3.697a1.5 1.5 0 010-2.122l.707-.707z\" fill=\"#fff\" fill-rule=\"nonzero\"/>",
    "attrs": {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});