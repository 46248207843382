define("ember-svg-jar/inlined/arrow right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M44.952 22.108c0-1.25-.478-2.424-1.362-3.308L30.627 5.831a2.5 2.5 0 00-3.536 0 2.511 2.511 0 000 3.546l10.574 10.57H2.484C1.102 19.948 0 21.081 0 22.464v.028c0 1.382 1.102 2.523 2.484 2.523h35.182L27.094 35.579a2.504 2.504 0 003.538 3.54l12.958-12.97a4.633 4.633 0 001.362-3.309v-.732z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "44.952",
      "height": "44.952",
      "viewBox": "0 0 44.952 44.952",
      "fill": "#002457"
    }
  };
  _exports.default = _default;
});