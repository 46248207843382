define("ember-svg-jar/inlined/search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M3.026 2.734c2.848-2.847 7.53-2.781 10.46.148 2.689 2.689 2.964 6.857.787 9.725l1.108 1.109.067-.06a1 1 0 011.327.078l4.95 4.95a1 1 0 010 1.414l-1.336 1.335a1 1 0 01-1.414 0l-4.95-4.95a1 1 0 01-.02-1.393l-1.107-1.108c-2.868 2.177-7.036 1.9-9.725-.788-2.929-2.93-2.995-7.612-.147-10.46zM4.44 4.15c-2.088 2.088-2.04 5.522.108 7.67 2.148 2.148 5.582 2.196 7.67.108 2.089-2.088 2.04-5.522-.108-7.67-2.147-2.148-5.582-2.197-7.67-.108z\" fill=\"#0056CD\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "22",
      "height": "22",
      "viewBox": "0 0 22 22",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});