define("ember-svg-jar/inlined/delete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>Remove</title><path d=\"M16.056 4.868l-1.13 15.363a2.628 2.628 0 01-2.622 2.436H5.696a2.628 2.628 0 01-2.621-2.436L1.945 4.868h14.11zM9.22 6.994h-.484a.603.603 0 00-.603.603v12.384c0 .333.27.603.603.603h.484c.333 0 .603-.27.603-.603V7.597a.603.603 0 00-.603-.603zm-3.38 0h-.484a.603.603 0 00-.603.603v12.384c0 .333.27.603.603.603h.484c.333 0 .603-.27.603-.603V7.597a.603.603 0 00-.603-.603zm6.76 0h-.484a.603.603 0 00-.603.603v12.384c0 .333.27.603.603.603h.484c.333 0 .603-.27.603-.603V7.597a.603.603 0 00-.603-.603zM10.035.111c.92 0 1.675.709 1.748 1.61l.003.1h4.893c.484 0 .877.392.877.877v.532c0 .19-.155.344-.345.344H.789a.345.345 0 01-.345-.344v-.532c0-.485.393-.877.877-.877h4.892l.004-.1A1.754 1.754 0 017.965.11h2.07z\" fill=\"#0056CD\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "18",
      "height": "23",
      "viewBox": "0 0 18 23",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});