define("ember-svg-jar/inlined/upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>Upload</title><g fill=\"none\" fill-rule=\"evenodd\"><path d=\"M54.4 12.727a6.054 6.054 0 016.055 6.054v32.438a6.054 6.054 0 01-6.054 6.054H15.599a6.054 6.054 0 01-6.054-6.054V18.781a6.054 6.054 0 016.054-6.054h38.802zm-1.582 28.57a2.096 2.096 0 00-2.096 2.097v4.355H19.278v-4.355a2.096 2.096 0 00-4.192 0v6.56c0 1.158.938 2.096 2.096 2.096h35.636a2.096 2.096 0 002.096-2.096v-6.56a2.096 2.096 0 00-2.096-2.096z\" fill=\"#0056CD\"/><path d=\"M35.093 21.635c.61.018 1.215.258 1.683.722l.016.016c.092.092.175.19.25.293l7.385 7.454a2.54 2.54 0 010 3.575 2.494 2.494 0 01-3.527.017l-.016-.017-3.38-3.41.001 9.916c0 .936-.759 1.696-1.696 1.696h-1.618c-.937 0-1.696-.76-1.696-1.696v-9.917l-3.38 3.411a2.494 2.494 0 01-3.526.017l-.016-.017a2.54 2.54 0 010-3.575l7.385-7.454a2.485 2.485 0 011.926-1.03h.209z\" fill=\"#FFF\"/></g>",
    "attrs": {
      "width": "70",
      "height": "70",
      "viewBox": "0 0 70 70",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});