define("ember-svg-jar/inlined/tooltip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><circle stroke=\"#D0D6E2\" fill=\"#FFF\" cx=\"8\" cy=\"8\" r=\"7.5\"/><path d=\"M8.799 10c.004-.301.03-.523.077-.666.075-.226.227-.423.455-.593l.42-.31c.427-.316.714-.576.864-.78.257-.334.385-.746.385-1.235 0-.798-.295-1.4-.885-1.806-.59-.407-1.33-.61-2.222-.61-.678 0-1.25.143-1.715.429-.738.448-1.13 1.208-1.178 2.28h1.71c0-.312.096-.613.287-.903.191-.29.516-.434.973-.434.466 0 .786.117.962.352.175.236.263.496.263.782 0 .248-.079.476-.236.683-.087.12-.202.232-.344.333l-.432.322c-.426.316-.69.595-.792.838-.103.243-.166.682-.19 1.318H8.8zM9 13v-2H7v2h2z\" fill=\"#0054D5\" fill-rule=\"nonzero\"/></g>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});