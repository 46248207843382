define("ember-svg-jar/inlined/small-close-white", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M1.464.05L5 3.585 8.536.05 9.95 1.464 6.414 5 9.95 8.536 8.536 9.95 4.999 6.414 1.464 9.95.05 8.536l3.535-3.537L.05 1.464 1.464.05z\" fill=\"#FFF\" fill-rule=\"nonzero\"/>",
    "attrs": {
      "width": "10",
      "height": "10",
      "viewBox": "0 0 10 10",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});