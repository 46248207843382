define("ember-svg-jar/inlined/notification-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><circle fill=\"#D70000\" cx=\"8\" cy=\"8\" r=\"8\"/><path d=\"M8.876 10.328l.196-6.055H6.928l.196 6.055h1.752zm-.874 2.796c.584 0 1.1-.495 1.108-1.108a1.126 1.126 0 00-1.108-1.096c-.614 0-1.116.49-1.108 1.096a1.1 1.1 0 001.108 1.108z\" fill=\"#FFF\" fill-rule=\"nonzero\"/></g>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});