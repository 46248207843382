define("ember-svg-jar/inlined/locked-red", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M7.75.75c.966 0 1.75.784 1.75 1.75v2.625h.875c.483 0 .875.392.875.875v4.375a.875.875 0 01-.875.875h-8.75a.875.875 0 01-.875-.875V6c0-.483.392-.875.875-.875H2.5V2.5c0-.966.784-1.75 1.75-1.75h3.5zm0 1.75h-3.5v2.625h3.5V2.5z\" fill=\"#d9534f\" fill-rule=\"nonzero\"/>",
    "attrs": {
      "width": "12",
      "height": "12",
      "viewBox": "0 0 12 12",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});