define('ember-changeset/utils/is-changeset', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = isChangeset;
  var get = Ember.get;
  var CHANGESET = exports.CHANGESET = '__CHANGESET__';

  function isChangeset(changeset) {
    return get(changeset, '__changeset__') === CHANGESET;
  }
});