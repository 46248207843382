define("ember-svg-jar/inlined/phone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M8.426 12.75a15.618 15.618 0 006.834 6.83l2.281-2.287a1.04 1.04 0 011.053-.254c1.162.383 2.411.59 3.702.59.576 0 1.037.462 1.037 1.038v3.63c0 .575-.461 1.036-1.037 1.036-9.737 0-17.63-7.892-17.63-17.63 0-.575.467-1.036 1.038-1.036h3.63c.575 0 1.036.461 1.036 1.037 0 1.29.208 2.54.591 3.702a1.04 1.04 0 01-.254 1.053L8.426 12.75z\" fill=\"#FFF\" fill-rule=\"nonzero\"/>",
    "attrs": {
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});