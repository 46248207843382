define("ember-svg-jar/inlined/add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M.444 8.352c0-.501.407-.908.908-.908h6.091l.001-6.092c0-.501.407-.908.908-.908h1.296c.501 0 .908.407.908.908v6.093h6.092c.501 0 .908.406.908.907v1.296a.907.907 0 01-.908.908h-6.093v6.092a.907.907 0 01-.907.908H8.352a.907.907 0 01-.908-.908v-6.092H1.351a.907.907 0 01-.908-.908V8.352z\" fill=\"#0056CD\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});