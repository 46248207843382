define("ember-svg-jar/inlined/collapse", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M16 4a1 1 0 011 1v14a1 1 0 01-2 0V5a1 1 0 011-1zM8.79 8.114c.163 0 .321.05.454.143l4.45 3.113a.79.79 0 01-.004 1.298l-4.45 3.079a.79.79 0 01-1.24-.65V8.905a.79.79 0 01.79-.791z\" fill=\"#F6F8FB\" fill-rule=\"nonzero\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});