define('ember-changeset/utils/computed/is-empty-object', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = isEmptyObject;
  var assert = Ember.assert,
      computed = Ember.computed,
      get = Ember.get,
      isPresent = Ember.isPresent;
  var keys = Object.keys;
  function isEmptyObject(dependentKey) {
    assert('`dependentKey` must be defined', isPresent(dependentKey));

    return computed(dependentKey, function () {
      return keys(get(this, dependentKey)).length === 0;
    }).readOnly();
  }
});