define("ember-svg-jar/inlined/cancel call", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M8.813 8.224a1.25 1.25 0 011.768 0l4.418 4.419 4.42-4.42a1.25 1.25 0 011.768 0l.59.59a1.25 1.25 0 010 1.768L17.356 15l4.42 4.42a1.25 1.25 0 010 1.767l-.59.59a1.25 1.25 0 01-1.768 0L15 17.356l-4.418 4.42a1.25 1.25 0 01-1.768 0l-.59-.59a1.25 1.25 0 010-1.768l4.42-4.42-4.42-4.418a1.25 1.25 0 010-1.768l.59-.59z\" fill=\"#FFF\" fill-rule=\"nonzero\"/>",
    "attrs": {
      "width": "30",
      "height": "30",
      "viewBox": "0 0 30 30",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});