define("ember-svg-jar/inlined/end call", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M17.825 3.72a15.618 15.618 0 00-9.661.004l.004 3.23c0 .403-.231.751-.565.924a11.805 11.805 0 00-3.036 2.2 1.033 1.033 0 01-1.466 0L.534 7.51a1.033 1.033 0 010-1.467C7.42-.84 18.58-.84 25.466 6.044a1.037 1.037 0 010 1.467l-2.567 2.567a1.033 1.033 0 01-1.466 0 11.805 11.805 0 00-3.036-2.2 1.04 1.04 0 01-.565-.924l-.007-3.234z\" fill=\"#FFF\" fill-rule=\"nonzero\"/>",
    "attrs": {
      "width": "26",
      "height": "11",
      "viewBox": "0 0 26 11",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});