define("ember-svg-jar/inlined/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>Edit</title><path d=\"M5.181 20.119L.323 21.677 1.88 16.82l3.3 3.3zM17.836 7.464L6.09 19.21l-3.3-3.3L14.536 4.164l3.3 3.3zm2.799-5.073a1.608 1.608 0 010 2.274l-1.89 1.89-3.3-3.3 1.89-1.89a1.608 1.608 0 012.274 0l1.026 1.026z\" fill=\"#0056CD\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "22",
      "height": "22",
      "viewBox": "0 0 22 22",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});