define("ember-svg-jar/inlined/undo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M354.24 47.4l39.879-39.879H272.196v121.924l60.801-60.801c56 50.066 77.251 132.004 46.918 205.235-18.585 44.869-53.531 79.815-98.4 98.4-44.866 18.585-94.288 18.585-139.158 0-44.869-18.585-79.815-53.531-98.4-98.4-18.585-44.869-18.585-94.29 0-139.159L16.24 123.24c-21.651 52.272-21.651 109.848 0 162.12 21.652 52.272 62.364 92.984 114.637 114.636 26.14 10.827 53.595 16.24 81.06 16.239 27.459-.001 54.927-5.414 81.061-16.239 52.271-21.652 92.983-62.364 114.636-114.636 35.105-84.76 10.898-179.534-53.394-237.96z\" fill=\"#0056CD\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "18",
      "height": "23",
      "viewBox": "0 0 423.754 423.754"
    }
  };
  _exports.default = _default;
});