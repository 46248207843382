define("ember-svg-jar/inlined/check-white", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M14.067 3.363l.112.112a1.927 1.927 0 010 2.725l-6.467 6.468c-.317.316-.716.5-1.13.55l-.155.012h-.155a1.92 1.92 0 01-1.285-.562L1.363 9.043a1.927 1.927 0 112.725-2.725l2.149 2.149 5.105-5.104a1.927 1.927 0 012.725 0z\" fill=\"#FFF\" fill-rule=\"nonzero\"/>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});