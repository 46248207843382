define("ember-svg-jar/inlined/dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M3.343 8h9.314a1 1 0 01.707 1.707l-4.657 4.657a1 1 0 01-1.414 0L2.636 9.707A1 1 0 013.343 8z\" fill=\"#333\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "16",
      "height": "18",
      "viewBox": "0 0 16 18",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});