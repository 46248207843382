function initializeProfitwell(apiKey){
  (function(i,s,o,g,r,a,m){
    i[o]=i[o]||function(){(i[o].q=i[o].q||[]).push(arguments)};
    a=s.createElement(g);m=s.getElementsByTagName(g)[0];a.async=1;a.src=r+'?auth='+apiKey;
    m.parentNode.insertBefore(a,m);
  })(window,document,'profitwell','script','https://public.profitwell.com/js/profitwell.js');
}

function trackUser(userEmail) {
  profitwell('start', { 'user_email': userEmail }); 
}
