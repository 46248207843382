define("ember-svg-jar/inlined/play", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M1.287.977l7.79 3.898a.889.889 0 010 1.59l-7.79 3.892A.889.889 0 010 9.562v-7.79A.889.889 0 011.287.977z\" fill=\"#0056CD\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "10",
      "height": "11",
      "viewBox": "0 0 10 11",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});