define("ember-svg-jar/inlined/arrow left hover", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M6.415 13.477L.57 7.71A1 1 0 01.57 6.288l5.846-5.8a.948.948 0 011.458 1.196L4.359 6.99l3.514 5.286a.95.95 0 01-1.458 1.201z\" fill=\"#555\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "8",
      "height": "14",
      "viewBox": "0 0 8 14",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});