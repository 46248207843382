define("ember-svg-jar/inlined/on call", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<circle cx=\"8\" cy=\"8\" r=\"3.5\" fill=\"#57AFF8\" stroke=\"#4296E4\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});