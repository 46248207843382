define("ember-svg-jar/inlined/chat light blue", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M6 21v-9a4 4 0 014-4h16a4 4 0 014 4v9a4 4 0 01-4 4h-9l-7 6v-6a4 4 0 01-4-4zm6-6a2 2 0 100 4 2 2 0 000-4zm6 0a2 2 0 100 4 2 2 0 000-4zm6 0a2 2 0 100 4 2 2 0 000-4z\" fill=\"#004CC6\" fill-rule=\"nonzero\"/>",
    "attrs": {
      "width": "36",
      "height": "36",
      "viewBox": "0 0 36 36",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});