define('ember-changeset/index', ['exports', 'ember-changeset/-private/relay', 'ember-changeset/utils/computed/object-to-array', 'ember-changeset/utils/computed/is-empty-object', 'ember-changeset/utils/is-promise', 'ember-changeset/utils/is-object', 'ember-changeset/utils/assign', 'ember-changeset/utils/object-without', 'ember-changeset/utils/includes', 'ember-changeset/utils/take', 'ember-changeset/utils/is-changeset', 'ember-deep-set'], function (exports, _relay, _objectToArray, _isEmptyObject, _isPromise, _isObject, _assign, _objectWithout, _includes, _take, _isChangeset, _emberDeepSet) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.changeset = changeset;

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var EmberObject = Ember.Object,
      _Ember$RSVP = Ember.RSVP,
      all = _Ember$RSVP.all,
      resolve = _Ember$RSVP.resolve,
      _Ember$computed = Ember.computed,
      not = _Ember$computed.not,
      readOnly = _Ember$computed.readOnly,
      Evented = Ember.Evented,
      emberArray = Ember.A,
      assert = Ember.assert,
      get = Ember.get,
      isArray = Ember.isArray,
      isEmpty = Ember.isEmpty,
      isEqual = Ember.isEqual,
      isNone = Ember.isNone,
      isPresent = Ember.isPresent,
      set = Ember.set,
      setProperties = Ember.setProperties,
      typeOf = Ember.typeOf;
  var keys = Object.keys;

  var CONTENT = '_content';
  var CHANGES = '_changes';
  var ERRORS = '_errors';
  var VALIDATOR = '_validator';
  var RELAY_CACHE = '_relayCache';
  var OPTIONS = '_options';
  var RUNNING_VALIDATIONS = '_runningValidations';
  var BEFORE_VALIDATION_EVENT = 'beforeValidation';
  var AFTER_VALIDATION_EVENT = 'afterValidation';

  function defaultValidatorFn() {
    return true;
  }

  var defaultOptions = { skipValidate: false };

  /**
   * Creates new changesets.
   *
   * @uses Ember.Evented
   * @param  {Object} obj
   * @param  {Function} validateFn
   * @param  {Object} validationMap
   * @param  {Object}  options
   * @return {Ember.Object}
   */
  function changeset(obj) {
    var validateFn = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : defaultValidatorFn;
    var validationMap = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    var options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};

    assert('Underlying object for changeset is missing', isPresent(obj));

    return EmberObject.extend(Evented, {
      /**
       * Internal descriptor for changeset identification
       *
       * @private
       * @property __changeset__
       * @type {String}
       */
      __changeset__: _isChangeset.CHANGESET,

      changes: (0, _objectToArray.default)(CHANGES, false),
      errors: (0, _objectToArray.default)(ERRORS, true),
      change: readOnly(CHANGES),
      error: readOnly(ERRORS),

      isValid: (0, _isEmptyObject.default)(ERRORS),
      isPristine: (0, _isEmptyObject.default)(CHANGES),
      isInvalid: not('isValid').readOnly(),
      isDirty: not('isPristine').readOnly(),

      init: function init() {
        this._super.apply(this, arguments);
        this[CONTENT] = obj;
        this[CHANGES] = {};
        this[ERRORS] = {};
        this[RELAY_CACHE] = {};
        this[VALIDATOR] = validateFn;
        this[OPTIONS] = (0, _assign.default)(defaultOptions, options);
        this[RUNNING_VALIDATIONS] = {};
      },
      unknownProperty: function unknownProperty(key) {
        return this._valueFor(key);
      },
      setUnknownProperty: function setUnknownProperty(key, value) {
        var changesetOptions = get(this, OPTIONS);
        var skipValidate = get(changesetOptions, 'skipValidate');

        if (skipValidate) {
          return this._setProperty(true, { key: key, value: value });
        }

        return this._validateAndSet(key, value);
      },
      toString: function toString() {
        var normalisedContent = (0, _assign.default)(get(this, CONTENT), {});
        return 'changeset:' + normalisedContent.toString();
      },
      willDestroy: function willDestroy() {
        var relayCache = get(this, RELAY_CACHE);
        for (var key in relayCache) {
          relayCache[key].destroy();
        }
      },
      prepare: function prepare(prepareChangesFn) {
        var changes = (0, _assign.default)(get(this, CHANGES));
        var preparedChanges = prepareChangesFn(changes);

        assert('Callback to `changeset.prepare` must return an object', (0, _isObject.default)(preparedChanges));

        set(this, CHANGES, preparedChanges);

        return this;
      },
      execute: function execute() {
        if (get(this, 'isValid') && get(this, 'isDirty')) {
          var content = get(this, CONTENT);
          var changes = get(this, CHANGES);

          var changedKeys = this._allKeys(changes);
          var resetProperties = {};

          changedKeys.forEach(function (key) {
            var _key$split = key.split('.'),
                _key$split2 = _slicedToArray(_key$split, 1),
                root = _key$split2[0];

            if (!resetProperties[root]) {
              resetProperties[root] = get(content, root);
            }
          });

          setProperties(content, changes);

          this._allKeys(resetProperties).forEach(function (key) {
            if (!changedKeys.includes(key)) {
              (0, _emberDeepSet.default)(content, key, get(resetProperties, key));
            }
          });
        }
        return this;
      },
      save: function save(options) {
        var _this = this;

        var content = get(this, CONTENT);
        var savePromise = resolve(this);

        this.execute();

        if (typeOf(content.save) === 'function') {
          savePromise = content.save(options);
        }

        return resolve(savePromise).then(function (result) {
          _this.rollback();
          return result;
        });
      },
      rollback: function rollback() {
        this._notifyVirtualProperties();
        var relayCache = get(this, RELAY_CACHE);

        for (var key in relayCache) {
          relayCache[key].rollback();
        }

        set(this, RELAY_CACHE, {});
        set(this, CHANGES, {});
        set(this, ERRORS, {});
        return this;
      },
      merge: function merge(changeset) {
        var content = get(this, CONTENT);
        assert('Cannot merge with a non-changeset', (0, _isChangeset.default)(changeset));
        assert('Cannot merge with a changeset of different content', get(changeset, CONTENT) === content);

        if (get(this, 'isPristine') && get(changeset, 'isPristine')) {
          return this;
        }

        var changesA = get(this, CHANGES);
        var changesB = get(changeset, CHANGES);
        var errorsA = get(this, ERRORS);
        var errorsB = get(changeset, ERRORS);
        var relayA = get(this, RELAY_CACHE);
        var relayB = get(changeset, RELAY_CACHE);
        var newChangeset = new Changeset(content, get(this, VALIDATOR));
        var newErrors = (0, _objectWithout.default)(keys(changesB), errorsA);
        var newChanges = (0, _objectWithout.default)(keys(errorsB), changesA);
        var mergedChanges = (0, _assign.default)(newChanges, changesB);
        var mergedErrors = (0, _assign.default)(newErrors, errorsB);
        var mergedRelays = (0, _assign.default)(relayA, relayB);

        newChangeset[CHANGES] = mergedChanges;
        newChangeset[ERRORS] = mergedErrors;
        newChangeset[RELAY_CACHE] = mergedRelays;
        newChangeset._notifyVirtualProperties();

        return newChangeset;
      },
      validate: function validate(key) {
        var _this2 = this;

        if (keys(validationMap).length === 0) {
          return resolve(null);
        }

        if (isNone(key)) {
          var maybePromise = keys(validationMap).map(function (validationKey) {
            return _this2._validateAndSet(validationKey, _this2._valueFor(validationKey, true));
          });

          return all(maybePromise);
        }

        return resolve(this._validateAndSet(key, this._valueFor(key)));
      },
      isValidating: function isValidating(key) {
        var runningValidations = get(this, RUNNING_VALIDATIONS);
        var ks = emberArray(keys(runningValidations));
        if (key) {
          return ks.includes(key);
        }

        return !isEmpty(ks);
      },
      addError: function addError(key, options) {
        var errors = get(this, ERRORS);
        if (!(0, _isObject.default)(options)) {
          var value = get(this, key);
          options = { value: value, validation: options };
        }

        this._deleteKey(CHANGES, key);
        this.notifyPropertyChange(ERRORS);
        this.notifyPropertyChange(key);

        return (0, _emberDeepSet.default)(errors, key, options);
      },
      pushErrors: function pushErrors(key) {
        var errors = get(this, ERRORS);
        var existingError = get(errors, key) || { validation: [] };
        var validation = existingError.validation;

        var value = get(this, key);

        if (!isArray(validation) && isPresent(validation)) {
          existingError.validation = [existingError.validation];
        }

        for (var _len = arguments.length, newErrors = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
          newErrors[_key - 1] = arguments[_key];
        }

        validation = [].concat(_toConsumableArray(existingError.validation), _toConsumableArray(newErrors));

        this._deleteKey(CHANGES, key);
        this.notifyPropertyChange(ERRORS);
        this.notifyPropertyChange(key);

        return set(errors, key, { value: value, validation: validation });
      },
      snapshot: function snapshot() {
        return {
          changes: (0, _assign.default)(get(this, CHANGES)),
          errors: (0, _assign.default)(get(this, ERRORS))
        };
      },
      restore: function restore(_ref) {
        var changes = _ref.changes,
            errors = _ref.errors;

        set(this, CHANGES, changes);
        set(this, ERRORS, errors);
        this._notifyVirtualProperties();

        return this;
      },
      cast: function cast() {
        var allowed = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

        var changes = get(this, CHANGES);

        if (isArray(allowed) && allowed.length === 0) {
          return changes;
        }

        var changeKeys = keys(changes);
        var validKeys = emberArray(changeKeys).filter(function (key) {
          return (0, _includes.default)(allowed, key);
        });
        var casted = (0, _take.default)(changes, validKeys);

        set(this, CHANGES, casted);

        return this;
      },
      _allKeys: function _allKeys(object) {
        var keys = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
        var scope = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';

        for (var key in object) {
          if (!object.hasOwnProperty(key)) {
            continue;
          }

          if ((0, _isObject.default)(object[key])) {
            this._allKeys(object[key], keys, scope + key + '.');
          } else {
            keys.push(scope + key);
          }
        }
        return keys;
      },
      _validateAndSet: function _validateAndSet(key, value) {
        var _this3 = this;

        var content = get(this, CONTENT);
        var oldValue = get(content, key);
        var validation = this._validate(key, value, oldValue);

        if ((0, _isPromise.default)(validation)) {
          this._setIsValidating(key, true);
          this.trigger(BEFORE_VALIDATION_EVENT, key);
          return validation.then(function (resolvedValidation) {
            _this3._setIsValidating(key, false);
            _this3.trigger(AFTER_VALIDATION_EVENT, key);
            return _this3._setProperty(resolvedValidation, { key: key, value: value, oldValue: oldValue });
          });
        }

        this.trigger(BEFORE_VALIDATION_EVENT, key);
        this.trigger(AFTER_VALIDATION_EVENT, key);
        return this._setProperty(validation, { key: key, value: value, oldValue: oldValue });
      },
      _validate: function _validate(key, newValue, oldValue) {
        var changes = get(this, CHANGES);
        var validator = get(this, VALIDATOR);
        var content = get(this, CONTENT);

        if (typeOf(validator) === 'function') {
          var isValid = validator({
            key: key,
            newValue: newValue,
            oldValue: oldValue,
            changes: (0, _assign.default)(changes),
            content: content
          });

          return isPresent(isValid) ? isValid : true;
        }

        return true;
      },
      _setProperty: function _setProperty(validation) {
        var _ref2 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
            key = _ref2.key,
            value = _ref2.value,
            oldValue = _ref2.oldValue;

        var changes = get(this, CHANGES);
        var isSingleValidationArray = isArray(validation) && validation.length === 1 && validation[0] === true;

        var _key$split3 = key.split('.'),
            _key$split4 = _slicedToArray(_key$split3, 1),
            root = _key$split4[0];

        if (validation === true || isSingleValidationArray) {
          this._deleteKey(ERRORS, key);

          if (!isEqual(oldValue, value)) {
            (0, _emberDeepSet.default)(changes, key, value);
          } else if (key in changes) {
            delete changes[key];
          }
          this.notifyPropertyChange(CHANGES);
          this.notifyPropertyChange(root);

          var errors = get(this, ERRORS);
          if (errors['__ember_meta__'] && errors['__ember_meta__']['values']) {
            delete errors['__ember_meta__']['values'][key];
            set(this, ERRORS, errors);
          }

          return value;
        }

        return this.addError(key, { value: value, validation: validation });
      },
      _setIsValidating: function _setIsValidating(key, value) {
        var runningValidations = get(this, RUNNING_VALIDATIONS);
        var count = get(runningValidations, key) || 0;

        if (value) {
          set(runningValidations, key, count + 1);
        } else {
          if (count === 1) {
            delete runningValidations[key];
          } else {
            set(runningValidations, key, count - 1);
          }
        }
      },
      _valueFor: function _valueFor(key) {
        var plainValue = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

        var changes = get(this, CHANGES);
        var errors = get(this, ERRORS);
        var content = get(this, CONTENT);
        var relay = get(this, RELAY_CACHE);

        if (errors.hasOwnProperty(key)) {
          return get(errors, key + '.value');
        }

        if (relay.hasOwnProperty(key)) {
          return relay[key];
        }

        if (this._hasOwnNestedProperty(changes, key)) {
          return get(changes, key);
        }

        var oldValue = get(content, key);

        if ((0, _isObject.default)(oldValue) && !plainValue) {
          return this._relayFor(key);
        }

        return oldValue;
      },
      _hasOwnNestedProperty: function _hasOwnNestedProperty(object, key) {
        var keyParts = key.split('.');
        var nextKey = keyParts.shift();
        if (nextKey in object) {
          if ((0, _isObject.default)(object[nextKey]) && keyParts.length) {
            return this._hasOwnNestedProperty(object[nextKey], keyParts.join('.'));
          } else {
            return true;
          }
        } else {
          return false;
        }
      },
      _relayFor: function _relayFor(key) {
        var shouldInvalidate = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

        var cache = get(this, RELAY_CACHE);
        var found = cache[key];

        if (shouldInvalidate) {
          found && found.destroy();
          delete cache[key];
        }

        if (isPresent(found)) {
          return found;
        }

        var relay = _relay.default.create({ key: key, changeset: this });
        cache[key] = relay;
        return relay;
      },
      _notifyVirtualProperties: function _notifyVirtualProperties() {
        var rollbackKeys = [].concat(_toConsumableArray(keys(get(this, CHANGES))), _toConsumableArray(keys(get(this, ERRORS))));

        for (var i = 0; i < rollbackKeys.length; i++) {
          this.notifyPropertyChange(rollbackKeys[i]);
        }
      },
      _deleteKey: function _deleteKey(objName, key) {
        var obj = get(this, objName);

        if (isNone(obj)) {
          return;
        }

        if (obj.hasOwnProperty(key)) {
          delete obj[key];
          this.notifyPropertyChange(objName + '.' + key);
          this.notifyPropertyChange(objName);
        }
      }
    });
  }

  var Changeset =
  /**
   * Changeset factory
   *
   * @class Changeset
   * @constructor
   */
  function Changeset() {
    _classCallCheck(this, Changeset);

    return changeset.apply(undefined, arguments).create();
  };

  exports.default = Changeset;
});