define("ember-svg-jar/inlined/pause", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M10.074 6c.327 0 .593.265.593.593v10.814a.593.593 0 01-.593.593H7.926a.593.593 0 01-.593-.593V6.593c0-.328.266-.593.593-.593h2.148zm6 0c.327 0 .593.265.593.593v10.814a.593.593 0 01-.593.593h-2.148a.593.593 0 01-.593-.593V6.593c0-.328.266-.593.593-.593h2.148z\" fill=\"#0056CD\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});